<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Verificación de correo electrónico</v-toolbar-title>
              </v-toolbar>

              <v-card-text>
                <p v-if="verified">
                  El correo <b>{{ email }}</b> fue confirmado.
                </p>
                <p v-else>No se pudo verificar el correo electrónico.</p>
              </v-card-text>

              <v-card-actions class="d-flex justify-center">
                <v-btn
                  v-if="verified"
                  color="primary"
                  @click="$router.push({ name: cartItemsLength > 0 ? 'cart' : 'login' })"
                >
                  <v-icon left>mdi-login</v-icon>
                  {{ cartItemsLength > 0 ? 'Continuar al carrito' : 'Ir al Inicio' }}
                </v-btn>

                <v-btn v-else color="primary" @click="$router.push({ name: 'landing' })">
                  <v-icon left>mdi-home</v-icon>
                  Ir a inicio
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// API
import AuthAPI from '@/api/auth';
import { mapActions } from 'vuex';

export default {
  name: 'VerifyEmail',

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    email: null,
    verified: false,
    cartItemsLength: 0,
  }),

  async created() {
    this.api = new AuthAPI();
    await this.verifyEmail();
    const cart = await this.getCartFromStorage();
    this.cartItemsLength = cart.length
  },

  methods: {
    ...mapActions('signup', ['getCartFromStorage']),
    async verifyEmail() {
      if (!this.token) return;

      this.$store.commit('showLoader');
      try {
        const { email, verified } = await this.api.verifyEmail({ token: this.token });
        this.email = email;
        this.verified = verified;
      } catch (error) {
        this.verified = false;
        console.log(error);
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped></style>
